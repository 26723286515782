/* Animations */
@keyframes HomeSlideIn {
    0% {transform: translateY(15%); opacity:0%;}
    100% {transform: translateY(0%); opacity:100%;}
}

/* Spotify */
.SpotifyContainer{
    margin: auto;
    margin-left: 0px;
    border-radius: 25px;
    width: 40%;
    height: 57vh;
}

#nowPlayingContainer {
    padding-top: 40px;
    padding-left: 20px;
    display: flex;
    width: fit-content;
    height: 200px;
    max-width: 100vw;
}
      
.nowPlayingBox {
    animation: HomeSlideIn 0.4s forwards;
    animation-delay: 0.15s;
    opacity:0%;
    transition: all 0.3s;
    animation-play-state : paused;
    position: relative;
    min-width: 300px;
    display: flex;
    height: 120px;
    border-radius: 25px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
    
.nowPlayingBox::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 25px;
    backdrop-filter: saturate(200%) blur(10px);
}
    
#trackArtwork a img {
    position: relative;
    padding: 10px;
    height: 100px;
    aspect-ratio: 1;
    border-radius: 25px;
    z-index: 2;
}

#trackArtwork img {
    position: relative;
    padding: 10px;
    height: 100px;
    aspect-ratio: 1;
    border-radius: 25px;
    z-index: 2;
}
    
.trackInfo {
    position: relative;
    display: block;
    z-index: 2;
    width: 45%;
}

#trackName a {
    font-family: 'Quicksand', sans-serif;
    font-weight: bolder;
    color: white;
    text-decoration: none;
    white-space: nowrap;
    padding-right: 13px;
}

#trackName  {
    font-family: 'Quicksand', sans-serif;
    font-weight: bolder;
    color: white;
    text-decoration: none;
    white-space: nowrap;
}

#artist a {
    font-family: 'Quicksand', sans-serif;
    color: white;
    text-decoration: none;
    white-space: nowrap;
}

#artist  {
    font-family: 'Quicksand', sans-serif;
    color: white;
    text-decoration: none;
}

#spotifyinfo{
    font-family: 'Poppins', sans-serif;
    color: white;
    text-decoration: none;
    white-space: nowrap;
    cursor: default;
}

.bi-bi-spotify{
    padding-right: 4px;
}

/* Platform Optimizations */  
/* Skinner Skinner Screen */
@media only screen and (max-width: 860px){
    .SpotifyContainer{
        width: 73%;
    }
}  

/* Media Query Phones */
@media only screen and (max-device-width: 450px) {
    /* Spotify */
    .SpotifyContainer{
        margin-left: 0px;
        height: 48vh;
        margin-left: -43px;
        width: 200%;
    }
    #nowPlayingContainer {
        padding-top: 0px;
        padding-left: 15px;
        transform: scale(0.83); 
    }
}

/* Media Query Extra Smaller Phones */
@media only screen and (max-device-height: 635px) {  
    /* Spotify */
    .SpotifyContainer{
        margin-left: 0px;
        height: 62vh;
        margin-left: -70px;
        width: 200%;
    }
    #nowPlayingContainer {
        padding-top: 0px;
        padding-left: 35px;
        transform: scale(0.8); 
    }
}
