/* Global Page Settings */
html{
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: transparent;
}

body::-webkit-scrollbar {
    background-color: transparent;
    display: none;
}

